import { ErrorMessage, Field, Form, Formik, FormikHelpers } from "formik";
import Footer from "../../fragments/Footer";
import Header from "../../fragments/Header";
import Sidebar from "../../fragments/Sidebar";
import * as Yup from "yup";
import { StudentFormValues } from "../../types/student";
import axios from "axios";
import { useState } from "react";

export const StudentFormSchema = Yup.object().shape({
  student_address: Yup.string().required("Address is required"),
  student_class_name: Yup.string().required("Class name is required"),
  student_name: Yup.string().required("Name is required"),
  student_parentage: Yup.string().required("Parentage is required"),
  student_phone_number: Yup.string().required("Phone number is required"),
  student_session_name: Yup.string().required("Session name is required"),
});

function AddStudent() {
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [errorMessage, setError] = useState<string>("");
  const [studentImage, setStudentImage] = useState<any>("");

  const initialValues: StudentFormValues = {
    student_address: "",
    student_class_name: "",
    student_name: "",
    student_parentage: "",
    student_phone_number: "",
    student_session_name: "",
    image: "",
  };

  const handleSubmit = async (
    values: StudentFormValues,
    formikHelpers: FormikHelpers<StudentFormValues>
  ) => {
    try {
      setError("");
      const formData = new FormData();
      formData.append("student_name", values.student_name);
      formData.append("student_address", values.student_address);
      formData.append("student_class_name", values.student_class_name);
      formData.append("student_parentage", values.student_parentage);
      formData.append("student_phone_number", values.student_phone_number);
      formData.append("student_session_name", values.student_session_name);
      formData.append("image", studentImage);

      await axios
        .post(`${process.env.REACT_APP_API_URL}/students`, formData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          if (response.status === 201) {
            setSuccessMessage("Student Added Successfully");
            setTimeout(() => {
              setSuccessMessage("");
            }, 3000);
          }
        })
        .catch((error) => {
          console.log(error);
          setError("An error Occurred");
          setTimeout(() => {
            setError("");
          }, 3000);
        });
      setStudentImage("");
      formikHelpers.resetForm();
    } catch (error) {
      setError("An error Occurred");
    }
  };

  return (
    <>
      <Sidebar />

      <div className="layout-wrapper">
        <Header />

        <div className="content">
          <div className="row row-cols-1 row-cols-md-3 g-4">
            <div className="col-lg-12 col-md-12">
              <div className="card widget h-100">
                <div className="card-header d-flex">
                  <h6 className="card-title d-none">None</h6>
                  <div className="d-flex gap-3 align-items-center ms-auto"></div>
                </div>
                <div className="card-body">
                  {successMessage !== "" ? (
                    <div className="alert alert-success" role="alert">
                      {successMessage}
                    </div>
                  ) : (
                    ""
                  )}
                  {errorMessage !== "" ? (
                    <div className="alert alert-success" role="alert">
                      {errorMessage}
                    </div>
                  ) : (
                    ""
                  )}
                  <Formik
                    initialValues={initialValues}
                    validationSchema={StudentFormSchema}
                    onSubmit={handleSubmit}
                  >
                    {({ errors, touched }) => (
                      <>
                        <h3>Add Student</h3>
                        <Form className="mb-5">
                          <div className="mb-3 my-5">
                            <div className="mb-3">
                              <Field
                                className="form-control  my-3"
                                name="student_name"
                                autoComplete="off"
                                type="text"
                                placeholder="Student Name"
                              />
                              <div style={{ color: "red" }}>
                                <ErrorMessage name="student_name" />
                              </div>
                            </div>
                            <div className="mb-3">
                              <Field
                                className="form-control  my-3"
                                name="student_address"
                                autoComplete="off"
                                placeholder="Student Address"
                                type="text"
                              />
                              <div style={{ color: "red" }}>
                                <ErrorMessage name="student_address" />
                              </div>
                            </div>
                            <div className="mb-3">
                              <Field
                                className="form-control  my-3"
                                name="student_class_name"
                                type="text"
                                autoComplete="off"
                                placeholder="Student Class Name"
                              />
                              <div style={{ color: "red" }}>
                                <ErrorMessage name="student_class_name" />
                              </div>
                            </div>
                            <div className="mb-3">
                              <Field
                                className="form-control  my-3"
                                name="student_parentage"
                                type="text"
                                autoComplete="off"
                                placeholder="Student Parentage"
                              />
                              <div style={{ color: "red" }}>
                                <ErrorMessage name="student_parentage" />
                              </div>
                            </div>
                            <div className="mb-3">
                              <Field
                                className="form-control  my-3"
                                name="student_phone_number"
                                type="text"
                                autoComplete="off"
                                placeholder="Student Phone Number"
                              />
                              <div style={{ color: "red" }}>
                                <ErrorMessage name="student_phone_number" />
                              </div>
                            </div>

                            <div className="mb-3">
                              <Field
                                className="form-control  my-3"
                                name="student_session_name"
                                type="text"
                                autoComplete="off"
                                placeholder="Student Session Name"
                              />
                              <div style={{ color: "red" }}>
                                <ErrorMessage name="student_session_name" />
                              </div>
                            </div>

                            <div className="mb-3">
                              <Field
                                className="form-control  my-3"
                                name="student_photograph"
                                type="file"
                                autoComplete="off"
                                placeholder="Student Photograph"
                                onChange={(event: any) => {
                                  setStudentImage(event.target.files[0]);
                                }}
                              />
                            </div>

                            <div
                              data-lastpass-icon-root="true"
                              style={{
                                position: "relative",
                                height: "0px",
                                width: "0px",
                                float: "left",
                              }}
                            ></div>
                          </div>
                          <div className="text-center text-lg-start">
                            <button type="submit" className="btn btn-primary">
                              Add Student
                            </button>
                          </div>
                        </Form>
                      </>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
}

export default AddStudent;
