import { Route, Routes } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import AddSchool from "../modules/schools/AddSchool";
import ListProduct from "../modules/schools/ListSchools";
import AddStudent from "../modules/students/AddStudent";
import ListStudent from "../modules/students/ListStudent";
import AccessDenied from "../pages/AccessDenied";
import Dashboard from "../pages/Dashboard";
import Login from "../pages/Login";
import Protected from "./PrivateRoutes";
// import UpdateStudent from "../modules/students/UpdateStudent";
import UpdateSchool from "../modules/schools/UpdateSchool";
import UpdateStudent from "../modules/students/UpdateStudent";
import LoginAsAdmin from "../pages/LoginAsAdmin";

function AppRoutes() {
  const authContext = useAuth();

  return (
    <div>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/login-as-admin" element={<LoginAsAdmin />} />
        <Route path="/" element={<Login />} />
        <Route
          path="/dashboard"
          element={
            <Protected isLoggedIn={authContext?.isLoggedIn}>
              <Dashboard />
            </Protected>
          }
        />

        <Route path="/schools" element={<ListProduct />} />
        {authContext?.isAdmin === true ? (
          <Route path="/schools/add" element={<AddSchool />} />
        ) : (
          <>
            <Route
              path="/schools/add"
              element={
                <Protected isLoggedIn={authContext?.isLoggedIn}>
                  <AccessDenied />
                </Protected>
              }
            />
          </>
        )}

        <Route
          path="/student"
          element={
            <Protected isLoggedIn={authContext?.isLoggedIn}>
              <ListStudent />
            </Protected>
          }
        />

        <Route
          path="/student/:id"
          element={
            <Protected isLoggedIn={authContext?.isLoggedIn}>
              <UpdateStudent />
            </Protected>
          }
        />

        <Route
          path="/schools/:id"
          element={
            <Protected isLoggedIn={authContext?.isLoggedIn}>
              <UpdateSchool />
            </Protected>
          }
        />

        {authContext?.isAdmin === false ? (
          <Route path="/student/add" element={<AddStudent />} />
        ) : (
          <>
            <Route
              path="/schools/add"
              element={
                <Protected isLoggedIn={authContext?.isLoggedIn}>
                  <AccessDenied />
                </Protected>
              }
            />
          </>
        )}
      </Routes>
    </div>
  );
}

export default AppRoutes;
