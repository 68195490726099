import { Link } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
function Sidebar() {
  const authContext = useAuth();

  return (
    <>
      <div className="menu">
        <div className="menu-header">
          <img
            width="100"
            className="logo"
            src="https://www.mclbaramulla.com/assets/imgs/theme/logo.png"
            alt="logo"
          />
        </div>
        <div className="menu-body">
          <ul>
            <li className="menu-divider"></li>
            <li>
              <Link className="active" to="/dashboard">
                <span className="nav-link-icon">
                  <i className="bi bi-bar-chart"></i>
                </span>
                <span>Dashboard</span>
              </Link>
            </li>
            {authContext?.isAdmin === true ? (
              <li>
                <ul>
                  <li>
                    <Link to="/schools/add">Add School</Link>
                  </li>
                  <li>
                    <Link to="/schools">List Schools</Link>
                  </li>
                </ul>
              </li>
            ) : (
              ""
            )}

            <li>
              <ul>
                {authContext?.isAdmin === false ? (
                  <li>
                    <Link to="/student/add">Add Student</Link>
                  </li>
                ) : (
                  ""
                )}
                <li>
                  <Link to="/student">List Student</Link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default Sidebar;
