import { Navigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
function Protected({ children }: any): any {
  const authContext = useAuth();
  if (!authContext?.isLoggedIn) {
    return <Navigate to="/" replace />;
  }
  return children;
}
export default Protected;
