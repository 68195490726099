import { Link } from "react-router-dom";

function Footer() {
  return (
    <>
      <footer className="content-footer">
        <div>
          © 2024 Designed and Developed by
          <Link to="https://mclbaramulla.com" target="_blank">
            &nbsp; Mehboob Colour Labs
          </Link>
        </div>
      </footer>
    </>
  );
}

export default Footer;
