import { ErrorMessage, Field, Form, Formik } from "formik";
import Footer from "../../fragments/Footer";
import Header from "../../fragments/Header";
import Sidebar from "../../fragments/Sidebar";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

export const StudentFormSchema = Yup.object().shape({
  student_address: Yup.string().required("Address is required"),
  student_class_name: Yup.string().required("Class name is required"),
  student_name: Yup.string().required("Name is required"),
  student_parentage: Yup.string().required("Parentage is required"),
  student_phone_number: Yup.string().required("Phone number is required"),
  student_session_name: Yup.string().required("Session name is required"),
});

export interface StudentFormValues {
  student_address: string;
  student_class_name: string;
  student_name: string;
  student_parentage: string;
  student_phone_number: string;
  student_session_name: string;
  student_photograph: string;
}

function UpdateStudent() {
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [errorMessage, setError] = useState<string>("");
  const [studentImage, setStudentImage] = useState<any>("");

  const [initialValues, setInitialValues] = useState<StudentFormValues | null>(
    null
  );

  const param = useParams<{ id: string }>();

  async function getStudent() {
    return await axios
      .get(`${process.env.REACT_APP_API_URL}/students/${param.id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setInitialValues(response.data.output);
      });
  }

  useEffect(() => {
    getStudent();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = async (values: StudentFormValues) => {
    try {
      setError("");
      const formData = new FormData();
      formData.append("student_name", values.student_name);
      formData.append("student_address", values.student_address);
      formData.append("student_class_name", values.student_class_name);
      formData.append("student_parentage", values.student_parentage);
      formData.append("student_phone_number", values.student_phone_number);
      formData.append("student_session_name", values.student_session_name);
      if (studentImage) {
        formData.append("image", studentImage);
      }

      await axios
        .put(
          `${process.env.REACT_APP_API_URL}/students/${param.id}`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            setSuccessMessage("Student Updated Successfully");
            setTimeout(() => {
              setSuccessMessage("");
            }, 3000);
          }
        })
        .catch((error) => {
          console.log(error);
          setError("An error occurred while updating the student.");
          setTimeout(() => {
            setError("");
          }, 3000);
        });
      setStudentImage(null);
      getStudent();
    } catch (error) {
      setError("An error occurred while updating the student.");
    }
  };

  return (
    <>
      <Sidebar />

      <div className="layout-wrapper">
        <Header />

        <div className="content">
          <div className="row row-cols-1 row-cols-md-3 g-4">
            <div className="col-lg-8 col-md-8">
              <div className="card widget h-100">
                <div className="card-header d-flex">
                  <h6 className="card-title d-none">None</h6>
                  <div className="d-flex gap-3 align-items-center ms-auto"></div>
                </div>
                <div className="card-body">
                  {successMessage !== "" ? (
                    <div className="alert alert-success" role="alert">
                      {successMessage}
                    </div>
                  ) : (
                    ""
                  )}
                  {errorMessage !== "" ? (
                    <div className="alert alert-success" role="alert">
                      {errorMessage}
                    </div>
                  ) : (
                    ""
                  )}
                  {initialValues ? (
                    <Formik
                      initialValues={initialValues}
                      validationSchema={StudentFormSchema}
                      onSubmit={handleSubmit}
                    >
                      <>
                        <h3>Update Student</h3>
                        <Form className="mb-5">
                          <div className="mb-3 my-5">
                            <div className="mb-3">
                              <Field
                                className="form-control  my-3"
                                name="student_name"
                                autoComplete="off"
                                type="text"
                                placeholder="Student Name"
                              />
                              <div style={{ color: "red" }}>
                                <ErrorMessage name="student_name" />
                              </div>
                            </div>
                            <div className="mb-3">
                              <Field
                                className="form-control  my-3"
                                name="student_address"
                                autoComplete="off"
                                placeholder="Student Address"
                                type="text"
                              />
                              <div style={{ color: "red" }}>
                                <ErrorMessage name="student_address" />
                              </div>
                            </div>
                            <div className="mb-3">
                              <Field
                                className="form-control  my-3"
                                name="student_class_name"
                                type="text"
                                autoComplete="off"
                                placeholder="Student Class Name"
                              />
                              <div style={{ color: "red" }}>
                                <ErrorMessage name="student_class_name" />
                              </div>
                            </div>
                            <div className="mb-3">
                              <Field
                                className="form-control  my-3"
                                name="student_parentage"
                                type="text"
                                autoComplete="off"
                                placeholder="Student Parentage"
                              />
                              <div style={{ color: "red" }}>
                                <ErrorMessage name="student_parentage" />
                              </div>
                            </div>
                            <div className="mb-3">
                              <Field
                                className="form-control  my-3"
                                name="student_phone_number"
                                type="text"
                                autoComplete="off"
                                placeholder="Student Phone Number"
                              />
                              <div style={{ color: "red" }}>
                                <ErrorMessage name="student_phone_number" />
                              </div>
                            </div>

                            <div className="mb-3">
                              <Field
                                className="form-control  my-3"
                                name="student_session_name"
                                type="text"
                                autoComplete="off"
                                placeholder="Student Session Name"
                              />
                              <div style={{ color: "red" }}>
                                <ErrorMessage name="student_session_name" />
                              </div>
                            </div>

                            <div
                              data-lastpass-icon-root="true"
                              style={{
                                position: "relative",
                                height: "0px",
                                width: "0px",
                                float: "left",
                              }}
                            ></div>
                          </div>
                          <div className="text-center text-lg-start">
                            <button type="submit" className="btn btn-primary">
                              Update Student
                            </button>
                          </div>
                        </Form>
                      </>
                    </Formik>
                  ) : (
                    <div className="alert alert-info" role="alert">
                      Loading...
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4">
              <img src={initialValues?.student_photograph} alt="student" />
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
}

export default UpdateStudent;
