import Footer from "../../fragments/Footer";
import Header from "../../fragments/Header";
import Sidebar from "../../fragments/Sidebar";
import { ErrorMessage, Field, Form, Formik, FormikHelpers } from "formik";
import { useState } from "react";
import { SchoolFormValues } from "../../types/school";
import * as Yup from "yup";
import { addSchool } from "../../services/schoolsService";

const initialValues: SchoolFormValues = {
  school_name: "",
  school_email: "",
  school_address: "",
  school_password: "",
};

const validationSchema = Yup.object({
  school_name: Yup.string()
    .required("Required")
    .min(10, "Minimum 10 characters"),
  school_email: Yup.string()
    .email("Invalid email address")
    .required("Required"),
  school_address: Yup.string()
    .required("Required")
    .min(10, "Minimum 10 characters"),
  school_password: Yup.string()
    .required("Required")
    .min(8, "Minimum 8 characters"),
});

function AddSchool() {
  const [successMessage, setSuccessMessage] = useState<string>("");

  const [errorMessage, setError] = useState<string>("");

  const handleSubmit = async (
    values: SchoolFormValues,
    formikHelpers: FormikHelpers<SchoolFormValues>
  ) => {
    try {
      setError("");
      const school: SchoolFormValues = {
        school_name: values.school_name,
        school_email: values.school_email,
        school_address: values.school_address,
        school_password: values.school_password,
      };
      const response = await addSchool(school);

      if (response !== undefined) {
        setSuccessMessage(response);
        setTimeout(() => {
          setSuccessMessage("");
        }, 2000);
        formikHelpers.resetForm();
      } else {
        setError("An error Occurred");
      }
    } catch (error) {
      setError("An error Occurred");
    }
  };

  return (
    <>
      <Sidebar />

      <div className="layout-wrapper">
        <Header />

        <div className="content">
          <div className="row row-cols-1 row-cols-md-3 g-4">
            <div className="col-lg-12 col-md-12">
              <div className="card widget h-100">
                <div className="card-header d-flex">
                  <h6 className="card-title d-none">None</h6>
                  <div className="d-flex gap-3 align-items-center ms-auto"></div>
                </div>
                <div className="card-body">
                  <h3>Add School</h3>
                  {successMessage !== "" ? (
                    <div className="alert alert-success" role="alert">
                      {successMessage}
                    </div>
                  ) : (
                    ""
                  )}
                  {errorMessage !== "" ? (
                    <div className="alert alert-danger" role="alert">
                      {errorMessage}
                    </div>
                  ) : (
                    ""
                  )}
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                  >
                    <Form>
                      <div className="my-2">
                        <label className="pb-3" htmlFor="school_name">
                          School Name
                        </label>
                        <Field
                          className="form-control"
                          placeholder="School Name"
                          type="text"
                          name="school_name"
                        />
                        <div style={{ color: "red" }}>
                          <ErrorMessage name="school_name" />
                        </div>
                      </div>
                      <div className="my-2">
                        <label className="pb-3" htmlFor="school_name">
                          School Email
                        </label>
                        <Field
                          className="form-control"
                          placeholder="School Email"
                          type="text"
                          name="school_email"
                        />
                        <div style={{ color: "red" }}>
                          <ErrorMessage name="school_email" />
                        </div>
                      </div>
                      <div className="my-2">
                        <label className="pb-3" htmlFor="school_address">
                          School Address
                        </label>
                        <Field
                          className="form-control"
                          placeholder="School Address"
                          type="text"
                          name="school_address"
                        />
                        <div style={{ color: "red" }}>
                          <ErrorMessage name="school_address" />
                        </div>
                      </div>
                      <div className="my-2">
                        <label className="pb-3" htmlFor="school_password">
                          School Password
                        </label>
                        <Field
                          className="form-control"
                          placeholder="School Password"
                          type="password"
                          name="school_password"
                        />
                        <div style={{ color: "red" }}>
                          <ErrorMessage name="school_password" />
                        </div>
                      </div>

                      <div className="d-flex justify-content-center my-3">
                        <button type="submit" className="btn btn-primary">
                          Add School
                        </button>
                      </div>
                    </Form>
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default AddSchool;
