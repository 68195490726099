import axios from "axios";
import { StudentFormValues } from "../types/student";

const API_URL = process.env.REACT_APP_API_URL;

// LIST SCHOOL CATEGORY  API START
const config = {
  headers: {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  },
};

export default async function getListOfStudents() {
  console.log("API is fetching");
  return await axios
    .get(`${API_URL}/students/`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`, // Add bearer token to Authorization header
      },
    })
    .then((res) => {
      if (res.status === 200) {
        return res.data.output;
      }
    })
    .catch((err) => console.log(err));
}

export async function getCountOfStudents() {
  return await axios
    .get(`${API_URL}/students/count`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        return res.data.output;
      }
    })
    .catch((err) => console.log(err));
}

export function getSingleStudent(id: number) {
  return axios
    .get(`${API_URL}/students/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`, // Add bearer token to Authorization header
      },
    })
    .then((res) => {
      if (res.status === 200) {
        return res.data.output;
      }
    })
    .catch((err) => console.log(err));
}

export async function addStudent(student: StudentFormValues) {
  try {
    const response = await axios.post(`${API_URL}/students`, student, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`, // Add bearer token to Authorization header
      },
    });

    if (response.status === 201) {
      return response.data.message;
    }
  } catch (error) {
    return undefined;
  }
}

export async function updateStudent(id: number, student: StudentFormValues) {
  try {
    const response = await axios.put(
      `${API_URL}/students/${id}`,
      student,
      config
    );

    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    return undefined;
  }
}

export async function deleteStudent(id: number) {
  try {
    const response = await axios.delete(`${API_URL}/students/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    return undefined;
  }
}
