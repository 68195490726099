import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;
export let isAdmin: boolean | undefined = undefined;
export let schoolId: number | undefined = undefined;
export let userId: number | undefined = undefined;

export async function login(email: string, password: string) {
  return axios
    .post(API_URL + "/auth/login", {
      email,
      password,
    })
    .then((response) => {
      if (response.data.token) {
        localStorage.setItem("token", response.data.token);
      }

      return response.data;
    })
    .catch((error) => {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        return error.response.data.message;
      } else {
        return "An error occurred";
      }
    });
}

export function logout() {
  localStorage.removeItem("user");
}

export function isLoggedIn() {
  axios
    .get(`${API_URL}/auth/islogged-in`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
    .then((response) => {
      isAdmin = response.data.isAdmin;
      userId = response.data.userId;
      schoolId = response.data.schoolId;
    })
    .catch((error) => {
      isAdmin = undefined;
      userId = undefined;
      schoolId = undefined;
    });
}
