import { useEffect, useState } from "react";
import { useAuth } from "../context/AuthContext";
import Footer from "../fragments/Footer";
import Header from "../fragments/Header";
import Sidebar from "../fragments/Sidebar";
import { getCountOfSchools } from "../services/schoolsService";
import { isLoggedIn } from "../services/userService";
import getListOfStudents, {
  getCountOfStudents,
} from "../services/studentService";
import { Student } from "../types/student";

function Dashboard() {
  const authContext = useAuth();

  const [student, setStudent] = useState<Student[]>([]);
  const [schoolCount, setSchoolCount] = useState<number>(0);
  const [studentCount, setStudentCount] = useState<number>(0);

  // LIST CODE
  async function init() {
    setStudent(await getListOfStudents());
    setSchoolCount(await getCountOfSchools());
    setStudentCount(await getCountOfStudents());
    console.log(student);
  }

  useEffect(() => {
    isLoggedIn();
    init();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <>
      <Sidebar />

      <div className="layout-wrapper">
        <Header />

        <div className="content">
          <div className="row row-cols-1 row-cols-md-3 g-4">
            <div className="col-lg-12 col-md-12">
              <div className="card widget h-100">
                <div className="card-header d-flex">
                  <h6 className="card-title d-none">None</h6>
                  <div className="d-flex gap-3 align-items-center ms-auto"></div>
                </div>
                <div className="card-body">
                  <h1>
                    Developed By{" "}
                    <a href="https://www.mclbaramulla.com">
                      Mehboob Colour Labs
                    </a>{" "}
                  </h1>
                </div>
              </div>
            </div>

            {authContext?.isAdmin === true ? (
              <>
                <div className="col-lg-4 col-md-12">
                  <div className="card h-100">
                    <div className="card-body">
                      <div className="d-flex mb-3">
                        <div className="display-7">
                          <i className="bi bi-building"></i>
                        </div>
                      </div>
                      <h4 className="mb-3">Schools</h4>
                      <div className="d-flex mb-3">
                        <div className="display-7">{schoolCount}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12">
                  <div className="card h-100">
                    <div className="card-body">
                      <div className="d-flex mb-3">
                        <div className="display-7">
                          <i className="bi bi-building"></i>
                        </div>
                      </div>
                      <h4 className="mb-3">Students</h4>
                      <div className="d-flex mb-3">
                        <div className="display-7">{studentCount}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12">
                  <div className="card h-100">
                    <div className="card-body">
                      <div className="d-flex mb-3">
                        <div className="display-7">
                          <i className="bi bi-building"></i>
                        </div>
                      </div>
                      <h4 className="mb-3">Users</h4>
                      <div className="d-flex mb-3">
                        <div className="display-7">1</div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
}

export default Dashboard;
