import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Sidebar from "../../fragments/Sidebar";
import Header from "../../fragments/Header";
import getListOfSchools, { deleteSchool } from "../../services/schoolsService";
import { School } from "../../types/school";

const ListSchools: React.FC = () => {
  const [schools, setSchools] = useState<School[]>([]);
  const [successMessage, setSuccessMessage] = useState<string>("");

  async function init() {
    const response = await getListOfSchools();
    setSchools(response);
  }

  async function handleDelete(id: number) {
    const response = await deleteSchool(id);
    console.log(response?.data.message);
    setSuccessMessage(response?.data.message);
    setTimeout(() => {
      setSuccessMessage("");
    }, 2000);
    init();
  }

  useEffect(() => {
    init();
  }, []);

  return (
    <>
      <Sidebar />
      <div className="layout-wrapper">
        <Header />
        <div className="content ">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <div className="d-md-flex gap-4 align-items-center">
                    <div className="d-none d-md-flex">List of All Schools</div>

                    <div className=" ms-auto">
                      <Link
                        to="/schools/add"
                        className="btn btn-primary "
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        Add Schools
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              {successMessage !== "" ? (
                <div className="alert alert-success" role="alert">
                  {successMessage}
                </div>
              ) : (
                ""
              )}
              <div
                className="table-responsive"
                style={{ overflow: "hidden", outline: "none" }}
                tabIndex={1}
              >
                <table
                  className="table table-custom table-lg mb-0"
                  id="products"
                >
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Name</th>
                      <th>Count</th>
                      <th>Address</th>
                      <th>Actions</th>
                    </tr>
                  </thead>

                  <tbody>
                    {schools.length > 0
                      ? schools.map((output, index) => (
                          <tr key={index}>
                            <td>{output.school_id}</td>
                            <td>{output.school_name}</td>
                            <td>{output.student_count}</td>
                            <td>{output.school_address}</td>

                            <td>
                              <div className="d-flex">
                                <Link to={`/schools/${output.school_id}`}>
                                  <i className="bi bi-pencil-fill"></i>
                                </Link>

                                <i
                                  style={{
                                    color: "#cc5833",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => handleDelete(output.school_id)}
                                  className="mx-4 bi bi-trash-fill"
                                ></i>
                              </div>
                            </td>
                          </tr>
                        ))
                      : "No Records Found"}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ListSchools;
