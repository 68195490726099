import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Sidebar from "../../fragments/Sidebar";
import Header from "../../fragments/Header";
import getListOfStudents, {
  deleteStudent,
} from "../../services/studentService";
import { useAuth } from "../../context/AuthContext";

interface Student {
  student_id: number;
  student_class_name: string;
  student_session_name: string;
  student_name: string;
  student_parentage: string;
  student_address: string;
  student_phone_number: number;
  student_photograph: string;
}

const ListStudent: React.FC = () => {
  const [students, setStudents] = useState<Student[]>([]);
  const [successMessage, setSuccessMessage] = useState<string>("");

  const authContext = useAuth();

  async function init() {
    setStudents(await getListOfStudents());
  }
  async function handleDelete(id: number) {
    const confirm = window.confirm("Are you sure you want to delete?");
    if (!confirm) {
      return false;
    }
    const response = await deleteStudent(id);
    setSuccessMessage(response?.data.message);
    setTimeout(() => {
      setSuccessMessage("");
    }, 2000);
    init();
  }

  async function exportToExcel() {
    // await axios
    //   .get(`${process.env.REACT_APP_API_URL}/students/export/to/excel`, {
    //     headers: {
    //       Authorization: `Bearer ${localStorage.getItem("token")}`,
    //     },
    //   })
    //   .then((response) => {
    //     const url = window.URL.createObjectURL(new Blob([response.data]));
    //     const link = document.createElement("a");
    //     link.href = url;
    //     link.setAttribute("download", "students.xlsx");
    //     document.body.appendChild(link);
    //     link.click();
    //   });
    const url = `${process.env.REACT_APP_API_URL}/students/export/to/excel`;
    window.open(url, "_blank");
  }

  async function exportToImages() {
    // await axios
    //   .get(`${process.env.REACT_APP_API_URL}/students/export/to/images`, {
    //     headers: {
    //       Authorization: `Bearer ${localStorage.getItem("token")}`,
    //     },
    //   })
    //   .then((response) => {
    //     const url = window.URL.createObjectURL(new Blob([response.data]));
    //     const link = document.createElement("a");
    //     link.href = url;
    //     link.setAttribute("download", "images.zip");
    //     document.body.appendChild(link);
    //     link.click();
    //   });
    const url = `${process.env.REACT_APP_API_URL}/students/export/to/images`;
    window.open(url, "_blank");
  }
  useEffect(() => {
    init();
  }, []);

  return (
    <>
      <Sidebar />
      <div className="layout-wrapper">
        <Header />
        <div className="content ">
          <div className="row">
            <div className="col-md-6">
              <button
                className="btn btn-primary"
                onClick={() => {
                  exportToImages();
                }}
              >
                Export Images
              </button>
            </div>
            <div className="col-md-6">
              <button
                className="btn btn-primary"
                onClick={() => {
                  exportToExcel();
                }}
              >
                Export To Excel
              </button>
            </div>
            <div className="col-md-12">
              {successMessage !== "" ? (
                <div className="alert alert-success" role="alert">
                  {successMessage}
                </div>
              ) : (
                ""
              )}
              <div
                className="table-responsive"
                style={{ overflow: "hidden", outline: "none" }}
                tabIndex={1}
              >
                <table
                  className="table table-custom table-lg mb-0"
                  id="products"
                >
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Photograph</th>
                      <th>Name</th>
                      <th>Address</th>
                      <th>Phone No.</th>
                      {authContext?.isAdmin === false ? <th>Actions</th> : ""}
                    </tr>
                  </thead>

                  <tbody>
                    {students.length > 0
                      ? students.map((output, index) => (
                          <tr key={index}>
                            <td>
                              <img
                                src={output.student_photograph}
                                style={{
                                  height: "50px",
                                  width: "50px",
                                  borderRadius: "50%",
                                }}
                                alt="student"
                              />
                            </td>
                            <td>
                              <Link to="#">{output.student_id}</Link>
                            </td>
                            <td>{output.student_name}</td>
                            <td>{output.student_address}</td>
                            <td>{output.student_phone_number}</td>
                            {authContext?.isAdmin === false ? (
                              <td>
                                <div className="d-flex">
                                  <Link to={`/student/${output.student_id}`}>
                                    <i className="bi bi-pencil-fill"></i>
                                  </Link>
                                  <i
                                    style={{
                                      color: "#cc5833",
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      handleDelete(output.student_id)
                                    }
                                    className="mx-4 bi bi-trash-fill"
                                  ></i>
                                </div>
                              </td>
                            ) : (
                              ""
                            )}
                          </tr>
                        ))
                      : "No Records Found"}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ListStudent;
