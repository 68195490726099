import { Link } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { LoginFormValues } from "../types/user";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useAuth } from "../context/AuthContext";

function Login() {
  const authContext = useAuth();
  const initialValues = {
    email: "jiehz@outlook.com",
    password: "12345",
  };
  const navigate = useNavigate();

  const [error, setError] = useState<string>("");

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email address").required("Required"),
    password: Yup.string()
      .required("Required")
      .min(3, "Password should be minimum 3 characters"),
  });

  const handleSubmit = async (values: LoginFormValues) => {
    const API_URL = process.env.REACT_APP_API_URL;
    const data = {
      email: values.email,
      password: values.password,
    };
    await axios.post(API_URL + "/schools/login", data).then((response) => {
      if (response.status === 200) {
        localStorage.removeItem("token");
        localStorage.setItem("token", response.data.output.token);
        authContext?.setIsAdmin(false);
        authContext?.setSchoolId(response.data.output.school.school_id);
        authContext?.setIsLoggedIn(true);
        navigate("/dashboard");
        setError("");
      }
    });
  };

  return (
    <>
      <div
        className="form-wrapper"
        style={{ padding: "180px 0px", backgroundColor: "#f17012" }}
      >
        <div className="container" style={{ display: "block" }}>
          <div className="card">
            <div className="row g-0">
              <div className="col">
                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <div className="d-block d-lg-none text-center text-lg-start">
                      <img
                        width="120"
                        src="assets/images/logo.svg"
                        alt="logo"
                      />
                    </div>
                    <div className="my-5 text-center text-lg-start">
                      <h1 className="display-8">Sign In</h1>
                    </div>

                    {error && <div className="alert alert-danger">{error}</div>}
                    <Formik
                      initialValues={initialValues}
                      validationSchema={validationSchema}
                      onSubmit={handleSubmit}
                    >
                      <Form className="mb-5">
                        <div className="mb-3">
                          <Field
                            type="email"
                            className="form-control"
                            placeholder="Enter email"
                            autoFocus
                            name="email"
                          />
                          <div className="text-danger">
                            <ErrorMessage name="email" component="div" />
                          </div>
                        </div>
                        <div className="mb-3">
                          <Field
                            type="password"
                            className="form-control"
                            placeholder="Enter password"
                            name="password"
                          />
                          <div className="text-danger">
                            <ErrorMessage name="password" component="div" />
                          </div>
                        </div>

                        <div className="text-center text-lg-start">
                          <p className="small"></p>
                          <button className="btn btn-primary" type="submit">
                            Log In
                          </button>
                        </div>

                        <div className="text-center text-lg-start my-5">
                          <p className="small"></p>
                          <Link to="/login-as-admin" className="" type="submit">
                            Log In as Admin
                          </Link>
                        </div>
                      </Form>
                    </Formik>
                    <div className="social-links justify-content-center"></div>
                  </div>
                </div>
              </div>
              <div className="col d-none d-lg-flex border-start align-items-center justify-content-between flex-column text-center">
                <div className="logo mt-md-5"></div>
                <div>
                  <img
                    src="https://www.mclbaramulla.com/assets/imgs/theme/logo.png"
                    alt="logo"
                    style={{ width: "150px", height: "150px" }}
                  />
                  <h3 className="fw-bold">
                    <span style={{ fontSize: "16px" }}>Powered by</span>{" "}
                    <a
                      style={{ color: "#f17012" }}
                      href="https://mclbaramulla.com/"
                    >
                      Mehboob Colour Lab
                    </a>
                  </h3>
                  <p className="lead my-5">
                    For any queries and customer support
                  </p>
                  <Link to="https://wallayl.com" className="btn btn-primary">
                    Contact Now
                  </Link>
                </div>
                <ul className="list-inline mb-md-5">
                  <li className="list-inline-item"></li>
                  <li className="list-inline-item"></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
