import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { ErrorMessage, Field, Form, Formik, FormikHelpers } from "formik";
import * as Yup from "yup";

// Importing Fragments
import Footer from "../../fragments/Footer";
import Header from "../../fragments/Header";
import Sidebar from "../../fragments/Sidebar";

// Define interface for school form values
interface SchoolFormValues {
  school_name: string;
  school_email: string;
  school_address: string;
  school_password: string;
}

// Define validation schema using Yup
const validationSchema = Yup.object({
  school_name: Yup.string()
    .required("Required")
    .min(10, "Minimum 10 characters"),
  school_email: Yup.string()
    .email("Invalid email address")
    .required("Required"),
  school_address: Yup.string()
    .required("Required")
    .min(10, "Minimum 10 characters"),
  school_password: Yup.string()
    .required("Required")
    .min(8, "Minimum 8 characters"),
});

// Component to update school
function UpdateSchool() {
  // State variables
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [initialValues, setInitialValues] = useState<SchoolFormValues | null>(
    null
  );

  // Get id from URL params
  const { id } = useParams<{ id: string }>();

  // Fetch school data on component mount
  useEffect(() => {
    async function fetchSchool() {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/schools/${id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setInitialValues(response.data);
      } catch (error) {
        setErrorMessage("An error occurred while fetching data.");
      }
    }
    fetchSchool();
  }, [id]);

  // Handle form submission
  const handleSubmit = async (
    values: SchoolFormValues,
    formikHelpers: FormikHelpers<SchoolFormValues>
  ) => {
    try {
      setErrorMessage("");
      const updatedSchool: SchoolFormValues = {
        school_name: values.school_name,
        school_email: values.school_email,
        school_address: values.school_address,
        school_password: values.school_password,
      };
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/schools/${id}`,
        updatedSchool,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (response.status === 200) {
        setSuccessMessage("School updated successfully.");
      }
    } catch (error) {
      setErrorMessage("An error occurred while updating school.");
    }
  };

  return (
    <>
      <Sidebar />
      <div className="layout-wrapper">
        <Header />
        <div className="content">
          <div className="row row-cols-1 row-cols-md-3 g-4">
            <div className="col-lg-12 col-md-12">
              <div className="card widget h-100">
                <div className="card-header d-flex">
                  <h6 className="card-title d-none">None</h6>
                  <div className="d-flex gap-3 align-items-center ms-auto"></div>
                </div>
                <div className="card-body">
                  <h3>Update School</h3>
                  {successMessage && (
                    <div className="alert alert-success" role="alert">
                      {successMessage}
                    </div>
                  )}
                  {errorMessage && (
                    <div className="alert alert-danger" role="alert">
                      {errorMessage}
                    </div>
                  )}
                  {initialValues !== null ? (
                    <Formik
                      initialValues={initialValues}
                      validationSchema={validationSchema}
                      onSubmit={handleSubmit}
                    >
                      <Form>
                        <div className="my-2">
                          <label className="pb-3" htmlFor="school_name">
                            School Name
                          </label>
                          <Field
                            className="form-control"
                            placeholder="School Name"
                            type="text"
                            name="school_name"
                            autoComplete="off"
                          />
                          <div style={{ color: "red" }}>
                            <ErrorMessage name="school_name" />
                          </div>
                        </div>
                        <div className="my-2">
                          <label className="pb-3" htmlFor="school_email">
                            School Email
                          </label>
                          <Field
                            className="form-control"
                            placeholder="School Email"
                            type="text"
                            name="school_email"
                            autoComplete="off"
                          />
                          <div style={{ color: "red" }}>
                            <ErrorMessage name="school_email" />
                          </div>
                        </div>
                        <div className="my-2">
                          <label className="pb-3" htmlFor="school_address">
                            School Address
                          </label>
                          <Field
                            className="form-control"
                            placeholder="School Address"
                            type="text"
                            name="school_address"
                            autoComplete="off"
                          />
                          <div style={{ color: "red" }}>
                            <ErrorMessage name="school_address" />
                          </div>
                        </div>
                        <div className="my-2">
                          <label className="pb-3" htmlFor="school_password">
                            School Password
                          </label>
                          <Field
                            className="form-control"
                            placeholder="School Password"
                            type="password"
                            autoComplete="off"
                            name="school_password"
                          />
                          <div style={{ color: "red" }}>
                            <ErrorMessage name="school_password" />
                          </div>
                        </div>
                        <div className="d-flex justify-content-center my-3">
                          <button type="submit" className="btn btn-primary">
                            Update School
                          </button>
                        </div>
                      </Form>
                    </Formik>
                  ) : (
                    <div>Loading...</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default UpdateSchool;
